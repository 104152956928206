import { FunctionComponent, useState } from "react";
import { 
    AddWidgetsButton,
    BtnHolder,
    HeaderAreaContainer,
    ModalContainer,
    ModalContent,
    NavContainer,
    NavItem,
    Title,
    MainContentContiner,
    SideBarNav,
    SideBarNavItem,
    SubMenuContainer,
    AddToMyViewBtnHolder 
} from "styles/funds/AddWidgets.styled";
import { 
    DarkLayer,
    CloseIcon,
    closeIcon,
} from "styles/crownAlert/CrownAlert.styled";
import { WIDGET_SELECTION_MAIN_TABS, 
    WIDGET_SELECTION_MAIN_TAB, 
    ONBOARDING_WIDGETS_TAB_ITEMS, 
    ONBOARDING_WIDGETS_TAB_ITEM,
    REPORTS_WIDGETS_TAB_ITEMS,
    REPORTS_WIDGETS_TAB_ITEM 
} from "constants/hubwiseDashboard";
import { setItem } from "utils/localStorage";

const AddWidgetComponent: FunctionComponent <{
    userPreferences: string[];
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    userPreferences,
    setUpdated
}) => {
    const [isActive, setIsActive]= useState<boolean>(false);
    const [mainTab, setMainTab] = useState<WIDGET_SELECTION_MAIN_TAB>(WIDGET_SELECTION_MAIN_TAB.ONBOARDING);
    const [onboardingTab, setOnboardingTab]= useState<ONBOARDING_WIDGETS_TAB_ITEM | undefined>();
    const [reportsTab, setReportsTab]= useState<REPORTS_WIDGETS_TAB_ITEM | undefined>();
    const [selectedItem, setSelectedItem]= useState<ONBOARDING_WIDGETS_TAB_ITEM | REPORTS_WIDGETS_TAB_ITEM | undefined>();

    const handleClick = () => {
        setIsActive(true);
    }
    const handleCloseModal = () => {
        setIsActive(false);
        setMainTab(WIDGET_SELECTION_MAIN_TAB.ONBOARDING);
        setOnboardingTab(undefined);
        setReportsTab(undefined);
        setSelectedItem(undefined);
    }

    const handleAddWidget = (selectedItem: string | undefined) => {
        handleCloseModal();

        if (selectedItem) {
            let found = false;

            if (userPreferences.length > 0 ) {
                userPreferences.map((item) => {
                    if(item === selectedItem) {
                        found= true;
                    }
                })
            }
            const newArr= userPreferences;
            if (!found) {
                newArr?.push(selectedItem);
                setItem("userPreferences", newArr);
                setUpdated(true);
            } else {
                console.log("Item is already on screen");
            }
        }
    }

    return (
        <>
             <HeaderAreaContainer>
                <BtnHolder>
                    <AddWidgetsButton onClick={handleClick}>Add Widget</AddWidgetsButton> 
                </BtnHolder>
            </HeaderAreaContainer>
            {isActive && 
                <>
                    <DarkLayer />
                    <ModalContainer>
                        <CloseIcon iconURL={closeIcon} onClick={handleCloseModal} />
                        <ModalContent>
                            <Title>Add a Widget</Title>
                            <NavContainer>
                                {WIDGET_SELECTION_MAIN_TABS.map((tabl)=>(
                                    <NavItem 
                                        key={tabl} 
                                        isActive={mainTab === tabl} 
                                        onClick={()=>{setMainTab(tabl); setSelectedItem(undefined); setOnboardingTab(undefined); setReportsTab(undefined)}}

                                    >
                                        {tabl}
                                    </NavItem>
                                ))}
                            </NavContainer>
                            <MainContentContiner>
                                <SideBarNav>
                                    {mainTab === WIDGET_SELECTION_MAIN_TAB.ONBOARDING && 
                                        <>
                                            {ONBOARDING_WIDGETS_TAB_ITEMS.map((tabl, index)=>(
                                                <SideBarNavItem 
                                                    key={tabl}
                                                    isActive={onboardingTab === tabl}
                                                    borderBottom={index !== ONBOARDING_WIDGETS_TAB_ITEMS.length - 1}
                                                    onClick={()=> {setOnboardingTab(tabl); setSelectedItem(tabl)}}
                                                >
                                                    {tabl}
                                                </SideBarNavItem>
                                            ))}
                                        </>
                                    }
                                    {mainTab === WIDGET_SELECTION_MAIN_TAB.REPORTS && 
                                        <>  
                                            {REPORTS_WIDGETS_TAB_ITEMS.map((tabl, index)=>(
                                                <SideBarNavItem 
                                                    key={tabl}
                                                    isActive={reportsTab === tabl}
                                                    borderBottom={index !== REPORTS_WIDGETS_TAB_ITEMS.length - 1}
                                                    onClick={()=>{setReportsTab(tabl); setSelectedItem(tabl)}}
                                                >
                                                    {tabl}
                                                </SideBarNavItem>
                                            ))}
                                        </>
                                    }
                                </SideBarNav> 
                                <SubMenuContainer>
                                    <AddToMyViewBtnHolder>
                                        <AddWidgetsButton onClick={()=>handleAddWidget(selectedItem)} disabled={!selectedItem}>Add to My View</AddWidgetsButton> 
                                    </AddToMyViewBtnHolder>
                                </SubMenuContainer>
                            </MainContentContiner>
                        </ModalContent>
                    </ModalContainer>
                </>
            }
        </>
    )
};

export default AddWidgetComponent