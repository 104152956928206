import { FunctionComponent, useState } from "react";
import { 
    ProgressBar,
    BarChartContainer,
    BarChartTitle,
    BarChartHolder
} from "styles/funds/Onboarding.styled";
import LinearProgressComponent from "../linearProgress/LinearProgress";
import ccIdList from "data/ccidList.json";


const AverageOnboardingTime: FunctionComponent<{
    data:typeof ccIdList;
    showTitle: boolean;
    boxShadow?: boolean;
}> =  ({
    data,
    showTitle,
    boxShadow
}) => {
    return (
        <BarChartContainer boxShadow= {boxShadow}>
            {showTitle && <BarChartTitle>Average Onboarding Completion Time</BarChartTitle>}
            <ProgressBar>
                <span></span>
                <span>0(months)</span>
                <span>1 month</span>
                <span>2 months</span>
            </ProgressBar>
            <BarChartHolder>
                {data.map((ccid,index) => (
                    <LinearProgressComponent
                        key={index} 
                        label={ccid.name} 
                        value={ccid.progress.percentage} 
                        barBackgroundColor="#bcbcbc" 
                        barColor={ccid.color?[0] ? ccid.color[0] : "#999999" : ""}
                        height={12}
                        days={ccid.progress.days}
                    />
                ))}
            </BarChartHolder>
        </BarChartContainer>   
    )
};

export default AverageOnboardingTime;