import styled from "styled-components";

export const MasterBuylistContainer = styled.div`
    height: 70vh;
    width: 80vw;
    overflow: scroll;
    padding: 12px;
    margin: 30px 10px 10px 10px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
`;

export const Th = styled.th`
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  font-weight: 600;
  padding: 0 24px;
  padding-bottom: 8px;
  text-wrap: nowrap;
`;

export const Tr = styled.tr<{ body?: boolean }>`
  text-align: center;
  height: ${(props) => (props.body === true ? "72px" : "")};
  cursor: ${(props) => (props.body === true ? "pointer" : "")};
`;