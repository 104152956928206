import { FunctionComponent, MouseEvent, useEffect, useState } from "react";
import { 
    WidgetTitle,
    WidgetHeaderArea,
    OptionsHolder,
    ContextMenu
} from "styles/funds/UserDesktopView.styled";
import { 
    ElipsisHolder
} from "styles/funds/Onboarding.styled";
import { setItem } from "utils/localStorage";

const WidgetHeaderAreaComponent: FunctionComponent<{
    isOption: boolean;
    setIsOption: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    id: number;
    activeId?: number;
    setActiveId: React.Dispatch<React.SetStateAction<number | undefined>>;
    userPreferences: string[];
    name: string;
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>
}> =  ({
    isOption,
    setIsOption,
    title,
    id,
    activeId,
    setActiveId,
    userPreferences,
    name,
    setUpdated
}) => {

    const handleEllipsisClick = (id: number) => {

        if(isOption) {
            if(id === activeId) {
                setIsOption(false);
                setActiveId(undefined);
            } else {
                setActiveId(id);
            }
        } else {
            setIsOption(true);
            setActiveId(id);
        }
    }

    const handleContextMenuClick = (name: string) => {
        const newPref = userPreferences.filter((acc)=> acc !== name);
        setItem("userPreferences", newPref);
        setIsOption(false);
        setUpdated(true);
    }
    
    return (
        <WidgetHeaderArea>
            <WidgetTitle>{title}</WidgetTitle>
            <OptionsHolder>
                <ContextMenu id="context-menu" display={isOption.toString()} active={id === activeId} onClick={()=>{handleContextMenuClick(name)}}>
                    <span>Remove from My View</span>
                </ContextMenu>
                <ElipsisHolder key={title} className="ellipsis-btn" onClick={()=>handleEllipsisClick(id)} />
            </OptionsHolder>
        </WidgetHeaderArea>
    )
};

export default WidgetHeaderAreaComponent