import React, { useState, FunctionComponent, useEffect } from "react";
import {
    Container,
    DropDownContainer,
    DropDownCover,
    DropDownItem,
    Input,
    InputContainer,
    arrowDown,
    ArrowDownIcon,
    SelectedOption
} from "styles/admin/SearchableDropdown.styled";

const SearchableDropdownComponent: FunctionComponent<{
    options: any[],
    labelToDisplay: string,
    setCurrentIndex?: React.Dispatch<React.SetStateAction<number>>
}> = ({
    options,
    labelToDisplay,
    setCurrentIndex
}) => {
    const [searchString, setSearchString]= useState<string>("");
    const [filteredOptions, setFilteredOptions]= useState<any[]>(options);
    const [showOptions, setShowOptions]= useState<boolean>(false);

    const handleChange = (e: { target: { value: any; }; }) => {
        const searchString= e.target.value;
        setSearchString(searchString);

        if(searchString) {
            const filtered= options.filter((option) => option[labelToDisplay].toLowerCase().includes(searchString.toLowerCase()));
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options);
        }
    }

    useEffect(()=>{
        if(searchString === "") {
            setFilteredOptions(options);
        }
    },[searchString])

    const handleOptionClick = (option: any) => {
        setSearchString("");
        setShowOptions(false);
        if(setCurrentIndex) {
            options.map((optionL, indexL) => {
                if(optionL[labelToDisplay] === option[labelToDisplay]) {
                    setCurrentIndex(indexL);
                }
            })
        }
    }

    const handleFocus = () => {
        setShowOptions(true);
    }

    const handleBlur = () => {
        setTimeout(()=> setShowOptions(false), 100);
    }

    return (
        <Container>
             <DropDownContainer onBlur={handleBlur}>
                <InputContainer>
                    <Input 
                        type="text" 
                        value={searchString}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        placeholder="Select other funds"
                    />
                    <ArrowDownIcon src={arrowDown} onClick={()=>{setShowOptions(!showOptions); setSearchString("")}}/>
                </InputContainer>
                
                {showOptions && (
                    <DropDownCover>
                        {filteredOptions.map((option, index) => (
                            <DropDownItem key={index} onClick={ () => handleOptionClick(option) }>{option[labelToDisplay]}</DropDownItem>
                        ))}
                    </DropDownCover>
                )}
            </DropDownContainer>
        </Container>
    )
}

export default SearchableDropdownComponent;