import { FunctionComponent, useState, useEffect } from "react";
import { 
    Container, 
    FundsContainer, 
    Title
} from "styles/funds/ActiveFunds.styled";
import NoData from "components/noData/NoData";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    FUNDS_PATH,
} from "config/googleCloudConfig";
import { getAccessToken } from "services/auth0/auth0";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { Accordion } from "components/party/accordion/Accordion";
import Funds from "components/party/funds/index";
import { 
    FundDocumentsType
} from "types/funds";
import LoadingSpinner from "components/LoadingSpinner";

const ActiveFundsComponent: FunctionComponent =  () => {
    const [fundsData, setFundsData] = useState<[]>([]);
    const [loading, setLoading] = useState(false);
    const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | null>(null);

    const token= getAccessToken();

    // TODO: get pid dynamically
    const partyId= "GB2B7TZLOYVXA";

    useEffect(()=>{
        setLoading(true);
    
        const indexFile = "index.json";
        const payload = {
          "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
          "fileName": indexFile,
          "filePath": `${FUNDS_PATH}/${partyId}`
        }
    
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
          setFundsData(data);
          setLoading(false);
        })

      },[partyId, token])
      
    return loading ? <LoadingSpinner /> : (
      <Container>
          <Title>Active Funds</Title>
          <FundsContainer>
            {!loading && fundsData.length > 0  ? fundsData.map((fund, index) => (
                <Accordion key={index} title={`Fund Name - ${fund["Fund Full Name"]}; FIGI - ${fund["FIGI"]}; ISIN - ${fund["ISIN"]}; SEDOL - ${fund["SEDOL"]}`} 
                children={
                    <Funds 
                        fundId={fund["Record Id"]} 
                        partyId={partyId} token={token} 
                        fundDocuments={fundDocuments!}
                    />
                } 
                small
            />
            )) : !loading && <NoData />}
          </FundsContainer>
          
      </Container>
    )
};

export default ActiveFundsComponent;