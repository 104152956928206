/* Google Cloud Functions URLs */
export const CREATE_FINANCIAL_GROUP_CACHE_GCF =
  "https://europe-west2-ssnc-407816.cloudfunctions.net/create-financial-group-cache";
// export const CREATE_FINANCIAL_GROUP_CACHE_GCF = 'http://localhost:8080/create-financial-group-cache';
export const ULTIMATE_OWNER_GCF =
  "https://europe-west2-ssnc-407816.cloudfunctions.net/test-ultimate-owner-neo4j";
export const LUCENE_GCF =
    "https://europe-west2-ssnc-407816.cloudfunctions.net/solr-search";
export const CALL_NEO4J_EXTERNALLY =
  "https://europe-west2-ssnc-407816.cloudfunctions.net/call-neo4j-externally";
// export const CALL_NEO4J_EXTERNALLY = "http://localhost:8083/call-neo4j-externally";
export const CHECKMATE_OCR_GCF_URL =
  "https://europe-west2-ssnc-407816.cloudfunctions.net/checkmate-ocr";
export const CHECKMATE_CONTROLLER_GCF =
  "https://europe-west2-ssnc-407816.cloudfunctions.net/checkmate-controller";
// export const WRITE_TO_MAIN_CACHE_GCF =
//   "https://europe-west2-ssnc-407816.cloudfunctions.net/write-to-main-cache-with-untar-old";

export const WRITE_TO_MAIN_CACHE_GCF =
    "https://europe-west2-ssnc-407816.cloudfunctions.net/extract-party-details";

/* Open Api URLs */
export const API_GATEWAY = "https://ssnc-gateway-cdn432sk.nw.gateway.dev";

//alice tickets entry points
export const CREATE_NEW_ALICE_TICKET = `${API_GATEWAY}/createAliceTickets`;
export const GET_ALICE_TICKETS = `${API_GATEWAY}/getAliceTickets`;
export const GET_ROOM_ATTRIBUTES= `${API_GATEWAY}/getRoomAttributes`;

//search results
export const GET_GUO = `${API_GATEWAY}/getGUO`;
export const SEARCH_RESULTS = `${API_GATEWAY}/getSearchSuggestionsAdvanced`;
export const RELATED_ENTITITES = `${API_GATEWAY}/getLegalNameHierarchy`;
export const GET_GUOS_FOR_ADVANCED_SEARCH = `${API_GATEWAY}/getGUOsForSearchSuggestion`;
export const GET_OBJECT_FROM_STORAGE = `${API_GATEWAY}/getObject`;

//party details
export const GET_PARTY_DETAILS = `${API_GATEWAY}/getPartyDetails`;
export const GET_LNH_STATS = `${API_GATEWAY}/getLNHStats`;