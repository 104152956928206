import { FunctionComponent } from "react";
import { 
    Container,
    CircularProgressContainer,
    ProgressText,
    ProgressNumber,
    PercentSign,
    Title
} from "styles/funds/circularProgress/CircularProgress.styled";

const CircularProgress: FunctionComponent<{
    height?: number,
    width?: number,    
    progressColor?: string,   
    progressText?: number,
    progressTextColor?: string,
    titleColor?: string,
    title?: string
}> =  ({
    height,
    width,
    progressColor,
    progressText,
    progressTextColor,
    titleColor,
    title
}) => {
    return (
        <Container>
            <CircularProgressContainer data-testid={"container"} width ={width} height= {height} color={progressColor} progress={progressText}>
                <ProgressText data-testid={"text"} color={progressTextColor}>
                    <ProgressNumber>{progressText}</ProgressNumber>
                    <PercentSign>%</PercentSign>
                </ProgressText>
            </CircularProgressContainer>
            <Title color={titleColor}>{title}</Title>
        </Container>
        
    )
};

export default CircularProgress;