import { FunctionComponent } from "react";
import {
    HeaderTextBig,
    HeaderText,
  } from "../../../styles/admin/AliceRequest.styled";
import { 
    TopContainer,
    TopBottomContainer,
    Header
} from "styles/funds/Onboarding.styled";
import ccIdList from "data/ccidList.json";
import AverageOnboardingTime from "./AverageOnboardingTime";
import CcIdProgress from "./CcIdProgress";

const OnboardingBox: FunctionComponent<{data: typeof ccIdList}> =  ({data}) => {
    return (
        <TopContainer>
            <Header data-testid="onboarding-box-header">
                <HeaderText>
                    <HeaderTextBig>Onboarding</HeaderTextBig>
                </HeaderText>
            </Header>
            <TopBottomContainer>
                <AverageOnboardingTime data={data} showTitle boxShadow/>
                <CcIdProgress data={data} showTitle boxShadow/> 
            </TopBottomContainer>
        </TopContainer>
    )
};

export default OnboardingBox;