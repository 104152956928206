import { FunctionComponent } from "react";
import {
    BottomContentContainer,
    FiltersContainer,
    SearchWrapper,
    TableContainer,
    Th
} from "styles/funds/Onboarding.styled";
import ccIdList from "data/ccidList.json";
import useFilters from "hooks/useFilter";
import { Search } from "../../filter/Search";
import { 
    Table,
    Thead,
    Tr,
    Tbody,
    Td,
    SpanWrapper,
    EmptySpan,
    TableDate,
    TableTime
} from "styles/admin/AdminTable.styled";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/index";

const ClientRequestsOnboardingTable: FunctionComponent<{data: typeof ccIdList}> =  ({data}) => {

    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str });
    };
    const navigate = useNavigate();

    const handleClick = (id: string) => {
        navigate(`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${id}`);
    }
    
    return (
        <BottomContentContainer>
            <FiltersContainer>
                <SearchWrapper>
                    <Search setData={setData} />
                </SearchWrapper>
            </FiltersContainer>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            { window.innerWidth > 500 && <Th>Client Channel ID</Th> }
                            <Th>Client Channel Name</Th>
                            { window.innerWidth > 500 && <Th>xxxxxx</Th> }
                            { window.innerWidth > 769 && <Th>Project Team</Th> }
                            { window.innerWidth > 769 && <Th>Last Updated</Th> }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredData.map((ccid,index) => (
                                <Tr key={index} body data-testid="row" onClick={ccid.name === "Standard Life" ? ()=>handleClick(ccid.id) : ()=>null}>
                                    { window.innerWidth > 500 && <Td>xxxx</Td> }
                                    <Td>{ccid.name}</Td>
                                    { window.innerWidth > 500 && <Td>{ccid.name}</Td> }
                                    { window.innerWidth > 769 && <Td>{ccid.name}</Td> }
                                    { window.innerWidth > 769 && <Td>
                                        <EmptySpan>
                                            <SpanWrapper>
                                                <TableDate>
                                                {format(new Date(ccid.start ? ccid.start : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")}
                                                <br />
                                                </TableDate>
                                                <TableTime>{format(new Date(new Date(ccid.start ? ccid.start : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)")), "HH:mm")}</TableTime>
                                            </SpanWrapper>
                                        </EmptySpan>
                                    </Td> }
                                </Tr>                            
                        ))}
                        
                    </Tbody>
                </Table>
            </TableContainer>
        </BottomContentContainer>
    )
};

export default ClientRequestsOnboardingTable;