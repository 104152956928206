import type { FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import Tooltip from "components/Tooltip";
import { DetailsIcon, DetailsIconWrapper, Container } from "styles/AliceModalTooltip.styled";
import { useAliceContext } from "contexts/AliceModalContext";
import { callGCFunction } from "services/callGCFunction";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "contexts/App";
import IframeCard from "components/ecp/EcpIframeCard";

const crownIcon = `${process.env.PUBLIC_URL}/assets/crown.svg`;

const AliceModalTooltip: FunctionComponent<
  PropsWithChildren<{
    selectedField: string;
    selectedKey?: string;
    selectedFund?: string;
    isCard?: boolean;
    isSmall?: boolean;
    isDisable?: boolean;
  }>
> = ({ children, selectedField, selectedKey, selectedFund, isCard, isSmall, isDisable }) => {
  const { menuToggle, isOpen } = useAliceContext();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!isOpen) {
        menuToggle(selectedField, selectedKey!, selectedFund);
      }
    event.stopPropagation();
  };

  return (
    <Container isCard>
      <div style={{ fontSize: isSmall ? "13px" : "16px" }}>{children}</div>
      {!isDisable && (
        <DetailsIconWrapper>
          <Tooltip content="Ask ALICE" askAlice>
            <DetailsIcon src={crownIcon} onClick={handleClick} />
          </Tooltip>
        </DetailsIconWrapper>
      )}
    </Container>
  );
};

export default AliceModalTooltip;
