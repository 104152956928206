// Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Search Suggestions' single items.
 */

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  SuggestionItem,
  SuggestionItemIcon,
  SuggestionItemName,
} from "styles/searchSuggestion/SearchSuggestionItem.styled";
import { PATH } from "constants/index";
import { RelatedEntity, SetSearchSuggestionsHasFinished } from "types";
import { SetIsSearch, SetPartyDetails, SetId } from "types";
import { getDataAndMetadata } from "utils/getDataAndMetaData";
import { useAppData } from "hooks/useAppData";
import { GeneralDetailsType } from "types/party";
import { useToast } from "hooks/useToast";
import { useParams } from "react-router-dom";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { getAccessToken } from "services/auth0/auth0";
import { GET_PARTY_DETAILS } from "config/googleCloudFunctionsConfig";

export function SearchSuggestionItem({
  entityToDisplay,
  setSearchSuggestionsHasFinished,
  isLast,
  isIdentifierSearch,
  setIsSearch,
  setPartyDetails,
  setId,
  setIsLoading,
  isHubwise,
}: {
  entityToDisplay: RelatedEntity;
  setSearchSuggestionsHasFinished: SetSearchSuggestionsHasFinished;
  isLast: boolean;
  isIdentifierSearch: boolean;
  setIsSearch?: SetIsSearch;
  setPartyDetails?: SetPartyDetails;
  setId?: SetId;
  setIsLoading?: SetIsSearch;
  isHubwise?: boolean;
}) {
  const [entityName, setEntityName] = useState<string>("");
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const IS_FUND_SEARCH = path === "/funds/search";
  const { setSelectedParty } = useAppData();
  const [_, setUserName] = useState<string>("");
  const { toast } = useToast();
  const token = getAccessToken();
  const [id,setIdL] = useState(entityToDisplay.id);

  const params = {
    pid: entityToDisplay.id
  }

  useEffect(() => {
    // if we already have a name, just use it
    if (entityToDisplay.name && entityToDisplay.name.trim() !== "") {
      setEntityName(entityToDisplay.name);
      // if we are at the last Search Suggestion Item, do not show anymore the loader bar in Search Suggestions
      if (isLast) {
        setSearchSuggestionsHasFinished(true);
      }
    }
  }, [entityToDisplay.name, isLast, setSearchSuggestionsHasFinished]);

const fetchPartyForFundManager = () => {
  if (token && entityToDisplay.id ) {

    callGCFunctionWIthAccessToken(token, GET_PARTY_DETAILS, params, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((data) => {
        if (data) {
          // get general details from data and set
          setSelectedParty({ ...(data?.["General Details"] as GeneralDetailsType), id });
          // set the rest of the data as profileDetails
          const details = { ...data };

          delete details?.["General Details"];
          if (setPartyDetails && setIsLoading && setId) {
            setPartyDetails(details);
            setIsLoading(false);
            setId(entityToDisplay.id);
          }
        }
      })
      .catch((err: Error) => toast(err.toString()));
  }
  }

  const handleClick = () => { 
    if (setIsSearch && setIsLoading) {
      setIsSearch(false); 
      setIsLoading(true);
      fetchPartyForFundManager();
    }  
  }

  return entityName !== "" ? (
    //  switch this pathname to funds search results page if we click from the funds search page else let it be the party profile search
    !isHubwise ? 
      <Link
        target="_blank"
        to={{
          pathname:
            (isIdentifierSearch
              ? `${PATH.PARTY}/`
              : `${IS_FUND_SEARCH ? PATH.FUND_SEARCH_RESULT : PATH.SEARCH_RESULT}/`) +
            entityToDisplay.id,
        }}
        style={{ color: "#333" }}
      >
        <SuggestionItem>
          {entityToDisplay.isGuo ? (
            <SuggestionItemIcon typeOfIcon="GUO" />
          ) : (
            (entityToDisplay.notInNeo && <SuggestionItemIcon typeOfIcon="NO_NEO" />) ||
            (!entityToDisplay.notInNeo && <SuggestionItemIcon typeOfIcon="NEO" />)
          )}
          <SuggestionItemName>{entityName}</SuggestionItemName>
        </SuggestionItem>
      </Link>
    : 
      <SuggestionItem onClick={handleClick}>
        {entityToDisplay.isGuo ? (
          <SuggestionItemIcon typeOfIcon="GUO" />
        ) : (
          (entityToDisplay.notInNeo && <SuggestionItemIcon typeOfIcon="NO_NEO" />) ||
          (!entityToDisplay.notInNeo && <SuggestionItemIcon typeOfIcon="NEO" />)
        )}
        <SuggestionItemName>{entityName}</SuggestionItemName>
      </SuggestionItem>
  ) : null;
}
