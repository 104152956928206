import DetailsConfirmationComponent from "components/detailsConfirmationForm/DetailsConfirmationForm";
import { FundType, FundDataRequestFormType, FundDetailsType } from "types/funds";
import { useEffect, useState } from "react";
import { FUNDS_PATH,
    FUNDS_DATA_REPOSITORY_BUCKET_NAME
 } from "config/googleCloudConfig";
 import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
 import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
 import { getAccessToken } from "services/auth0/auth0";
import LoadingSpinner from "components/LoadingSpinner";
import { GET_PARTY_DETAILS } from "config/googleCloudFunctionsConfig";
import { useToast } from "hooks/useToast";

const FundDetailsConfirmation = () => {

    const [currentIndex, setCurrentIndex]= useState<number>(0);
    const [formObj, setFormObj] = useState<FundDataRequestFormType>();
    const [loading, setLoading]= useState<boolean>(true);
    const [fundsLeftToConfirm, setFundsLeftToConfirm]= useState<number>();
    const [totalNo, setTotalNoFunds] = useState<number>();
    const [fundsConfirmed, setFundsConfirmed]= useState<boolean>(false);
    const [fundsData, setFundsData]= useState<FundType[]>([]);
    const [clientName, setClientName]= useState<string>("");
    // TODO: get these ids dynamically
    const partyId= "GBAGI0ZCAQTWB";
    const clientId= "GB3YPCKJXUVEE";

    const token = getAccessToken();
    const { toast } = useToast();

    const setFormObjData = (data: FundDetailsType) => {
      const newObj: FundDataRequestFormType= {
        "Available via Morningstar Y/N": data["Available via Morningstar"],
        "Currency (GBP, EUR,USD)": data.Currency,
        "Trading frequency i.e. Daily or weekly (Hubwise cannot support weekly trading)": data["Trading Frequency"],
        "STP (Tradeable via EMX/CALASTONE/Both)": data.STP,
        "Settlement Bank/Account/Sort Code (Hubwise can only support Faster Payment to UK Bank)": data["Settlement Bank Account"],
        "Clean/Dirty Shareclass (Hubwise cannot support Dirty Shareclasses)": `${data["Clean Share Class"]}/ ${data["Dirty Share Class"]}`,
        "Dividend Frequency (Hubwise cannot support Daily Dividends)": data["Dividend Frequency"],
        "ISIN Code": data.ISIN,
        "Fund Name": data["Fund Full Name"],
        "Fund Provider Name": data["Fund Provider Name"],
        "Fund Administrator Name": data["Fund Administrator Name"],
        "SEDOL": data.SEDOL,
        "Share Class": data["Share Class"],
        "Confirmation to use existing account [Insert Number]/New Account Required?": "",
        "Restricted Shareclass  (i.e. ringfencing* required) Y/N *Hubwise can ringfence fund to firm level": data["Restricted Share Class"],
        "CREST Settlement? Y/N": data["CREST Settlement"],
        "IA Sector": data["IA Sector"],
        "Status Open/Soft Closed": data.Status,
        "ON / OFFSHORE": data["On/Offshore"],
        "Income or Accumulation": data["Income or Accumulation"],
        "Standard Initial Charge %": data["Standard Initial Charge %"],
        "Hubwise Initial Charge %": data["Hubwise Initial Charge %"],
        "OCF %": data["Coalesce OCF %"],
        "Settlement period (T+) (Hubwise cannot support T+0)": data["Settlement period (T+)"],
        "Fund Access Requirements Min investment/Min holding/Min top up/ OR Please state whether they are Waived" : data["Fund Access Requirements"],
        "Tradeable via Telephone (Insert Tel No.)": data["Tradeable via Telephone"],
        "Tradeable via fax (Insert Fax No.)": data["Tradeable via Fax"],
        "Calastone Identifier": data.Calastone,
        "EMX Product Code": data["EMX Product Code"],
        "EMX Provider Code": data["EMX Provider Code"],
        "Trade Cut Off Point": data["Trade Cut Off Point"],
        "Valuation Point": data["Valuation Point"],
        "Administrator Portal Available Y/N (Statements/valuations/reporting)": data["Administrator Portal Available"]
      }
      setFormObj(newObj);
    }

    useEffect(()=>{
      setLoading(true);
      const indexFile = "index.json";
      const payload = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": indexFile,
        "filePath": `${FUNDS_PATH}/${partyId}`
      }
      // call for all funds that need to be confirmed related to this fund manager
      callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((data) => {
        setFundsData(data);
        setCurrentIndex(0);
        // call for the first fund's details
        const fundFileName= `${data[0]["Record Id"]}.json`;
        const fundPayload = {
          "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
          "fileName": fundFileName,
          "filePath": `${FUNDS_PATH}/${partyId}`
        }
            
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, fundPayload, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((fundData: FundDetailsType) => {
          setFormObjData(fundData);  
        }) 

        setTotalNoFunds(data.length);
        setFundsLeftToConfirm(data.length);
      })

      const clientParams = {
        pid: clientId
      }
      // call for the client legal name
      callGCFunctionWIthAccessToken(token, GET_PARTY_DETAILS, clientParams, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
          if (data) {
           setClientName(data?.["General Details"]["Legal Name"]);
           setLoading(false);
          } else {
            setClientName("");
            setLoading(false);
          }
        })
        .catch((err: Error) => toast(err.toString()));

    },[partyId, clientId, token])  

    useEffect(()=>{
      setLoading(true);
      // call for next fund's details
      if(fundsData[currentIndex] !== undefined) {
        const fileName= `${fundsData[currentIndex]["Record Id"]}.json`;
        const payload = {
          "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
          "fileName": fileName,
          "filePath": `${FUNDS_PATH}/${partyId}`
        }
            
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data: FundDetailsType) => {
          setFormObjData(data);  
          setLoading(false);
        }) 
        setFundsLeftToConfirm(fundsData.length - currentIndex);
      } else if(currentIndex !== 0 && fundsData[currentIndex] === undefined) {
        setFundsConfirmed(true);
        setFundsLeftToConfirm(0);
        setLoading(false);
      }
    },[currentIndex]);

    return ( 
      <>
        {
          loading? <LoadingSpinner /> : 
          formObj!== undefined && 
            <DetailsConfirmationComponent 
              data={formObj} 
              title="Fund Data Request" 
              currentIndex= {currentIndex} 
              setCurrentIndex= {setCurrentIndex} 
              loading= {loading}
              fundsLeftToConfirm= {fundsLeftToConfirm}
              totalNoFunds= {totalNo}
              fundsConfirmed= {fundsConfirmed}
              clientName= {clientName}
              allFundsData= {fundsData}
            />
        }
      </>
      
    )
}

export default FundDetailsConfirmation;