import { FunctionComponent } from "react";
import NoData from "components/noData/NoData";
import { 
    Container,
    Title, 
    QHolder,
    QRow,
    Input,
    InputWrapper,
    FormLabel,
    SubmitBtnHolder,
    ProgressContainer,
    FundsLeftToConfirmP,
    ConfirmationP,
    HeaderSectionHolder,
    IdsHolder,
    IdsDiv,
    ValueP,
    LabelP,
    HeaderSectionTitle,
    Switch,
    QAccordionHolder,
    QPermissionHolder,
    Section,
    NavHolder
} from "../../styles/fundDetailsConfirmation/FundDetailsConfirmation.styled";
import { useState, useEffect } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import LoadingSpinner from "components/LoadingSpinner";
import CircularProgress from "components/hubwise-dashboard/circularProgress/CircularProgress";
import { FundDataRequestFormType, FundType } from "types/funds";
import SearchableDropdownComponent from "components/admin/table/SearchableDropdown";

const DetailsConfirmationComponent: FunctionComponent<{
    data?: FundDataRequestFormType, 
    title: string, 
    hideSubmit?: boolean,
    currentIndex?: number,
    setCurrentIndex?: React.Dispatch<React.SetStateAction<number>>,
    loading?: boolean,
    fundsLeftToConfirm?: number,
    totalNoFunds?: number,
    fundsConfirmed?: boolean,
    hideProgress?: boolean,
    hideTitleSection?: boolean,
    hideNavSection?: boolean,
    clientName?: string,
    allFundsData: FundType[]
}> =  ({
    data, 
    title, 
    hideSubmit,
    currentIndex,
    setCurrentIndex,
    loading,
    fundsLeftToConfirm,
    totalNoFunds,
    fundsConfirmed,
    hideProgress,
    hideTitleSection,
    hideNavSection,
    clientName,
    allFundsData
}) => {

    const [formValues, setFormValues] = useState<{[key: string]: any}>(); 
    const [percentage, setPercentage]= useState<number>(0);
    const [isConfirmation, setIsConfirmation]= useState<boolean>(true);

    useEffect(() => {
        setFormValues(data);
    }, [data]);

    useEffect(()=>{
        if(currentIndex !== undefined && totalNoFunds !== undefined) {
            if(fundsLeftToConfirm === 0) {
                setPercentage(100);
            }
            setPercentage(Math.floor(100/totalNoFunds*currentIndex));
        }
    },[fundsLeftToConfirm])

    const handleInputChange = (label: string, value: string) => {
        setFormValues(prevState => ({ ...prevState, [label]: value }));
    };

    const handleToggle = () => {
        setIsConfirmation(!isConfirmation);
    }

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        //TODO: Send formValues to backend
        console.log("Form submitted:", formValues);

        // move to the next fund data
        if(currentIndex !== undefined && setCurrentIndex !== undefined) {
            setCurrentIndex(currentIndex+1);
        }
        // scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    
    return data ? ( 
        <>
            { loading ? <LoadingSpinner /> :
            <Container>
                <Title>{title}</Title>
                <HeaderSectionHolder>
                    {fundsLeftToConfirm !== 0 && !hideProgress && 
                        <ProgressContainer>
                            <FundsLeftToConfirmP>{`Funds left to confirm: ${fundsLeftToConfirm}`}</FundsLeftToConfirmP>
                            <CircularProgress
                                width={100} 
                                height={100} 
                                title={"Progress"}
                                progressText={percentage}
                                progressColor={"#364073"}
                            />  
                        </ProgressContainer>
                    }
                    {!hideTitleSection && fundsLeftToConfirm !== 0 &&
                        <IdsHolder>
                            <HeaderSectionTitle>Current Fund</HeaderSectionTitle>
                            <IdsDiv>
                                <LabelP>Fund Name: </LabelP>
                                <ValueP>{data["Fund Name"]}</ValueP>
                            </IdsDiv>
                            <IdsDiv>
                                <LabelP>ISIN: </LabelP>
                                <ValueP>{data["ISIN Code"]}</ValueP>
                            </IdsDiv>
                            <IdsDiv>
                                <LabelP>SEDOL: </LabelP>
                                <ValueP>{data.SEDOL}</ValueP>
                            </IdsDiv>
                        </IdsHolder>
                    }
                    {!hideNavSection && fundsLeftToConfirm !== 0 && 
                        <NavHolder>
                            <SearchableDropdownComponent options={allFundsData} labelToDisplay={"ISIN"} setCurrentIndex= {setCurrentIndex} />
                        </NavHolder>
                    }
                </HeaderSectionHolder>

                

                {formValues && Object.entries(formValues).length > 0 && !fundsConfirmed && fundsLeftToConfirm !== 0 ? 
                    <>
                        <QHolder onSubmit={handleSubmit}>
                            <Section>
                                <QAccordionHolder>  
                                    {Object.entries(formValues).map(([label, value]) => (
                                        <QRow key={label}>
                                            <FormLabel htmlFor={label}>{label}</FormLabel>
                                            <InputWrapper>
                                                <Input
                                                    type="text"
                                                    id={label}
                                                    value={value}
                                                    onChange={(e) => handleInputChange(label, e.target.value)}
                                                />
                                            </InputWrapper>
                                        </QRow>
                                    ))}
                                </QAccordionHolder>
                            </Section>
                            
                            {clientName && clientName.length > 0 && 
                                <Section>
                                    <QPermissionHolder>
                                        <QRow>
                                            <FormLabel>List of clients currently trading this fund:</FormLabel>
                                            <p>Place holder for list of clients currently trading this fund</p>
                                        </QRow>
                                        <QRow>
                                            <FormLabel>{clientName} would like to trade this fund. Please confirm if you agree:</FormLabel>
                                            <InputWrapper>
                                                <label>
                                                    Yes / No :
                                                    <Switch>
                                                        <input type="checkbox" checked={!isConfirmation} onChange={handleToggle} />
                                                        <span></span>
                                                    </Switch>
                                                </label>
                                            </InputWrapper>
                                        </QRow>
                                    </QPermissionHolder>
                                </Section>
                            }
                        </QHolder>
                        {!hideSubmit && (
                            <SubmitBtnHolder>
                                <SubmitButton
                                    onClick={handleSubmit}
                                    text={"Submit"}
                                    type={"submit"}
                                    disabled={false}
                                />
                            </SubmitBtnHolder>
                        )}
                        
                    </> 
                    :  !fundsConfirmed && <NoData /> 
                }
                {fundsConfirmed && fundsLeftToConfirm !== 0 && <ConfirmationP>Thank you for confirming all funds! You can now close this page. </ConfirmationP>}
                
            </Container>}
        </>
    ) : <NoData />
};

export default DetailsConfirmationComponent;