import styled from "styled-components";

export const closeIcon = `${process.env.PUBLIC_URL}/assets/crown-alert-close-icon.svg`;

export const Container = styled.div`
    height: 78vh;
    width: 98%;
    margin-top: 50px;
`;

export const SearchHolder = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const PartyProfileHolder = styled.div`
    display: flex;
    align-items: center;
    width: 97%;
    padding: 20px;
`;

export const CloseIcon = styled.div<{ iconURL: string }>`
  position: absolute;
  top: 100px;
  right: 40px;
  width: 32px;
  height: 32px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    width: 100%;
`;

export const ContactInfoHolder = styled.div`
    width: 98%;
    margin: auto;
`;