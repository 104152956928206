import styled from "styled-components";

export const ContainerFunds = styled.div`
  margin-bottom: 40px;
  width: 100%;
  overflow-x: hidden;
`;

export const FundManagersSectionHolder = styled.div`
  margin-bottom: 40px;
  padding: 30px 50px;
`;

export const BannerRequests = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.WHITE === "#FFFFFF" ? theme.colors.PRIMARY[25] : theme.colors.PRIMARY[150]};
  padding-bottom: 70px;
  padding: 30px 50px;
`;

export const HubwiseContainerFunds = styled.div`
  margin-bottom: 40px;
  background-color: #D3D3D2;
  padding: 20px 20px;
`;

export const Field = styled.div<{ legal: boolean; isArray: boolean }>`
  font-family: Roboto Condensed;
  width: 50%;
  height: ${({ isArray }) => (isArray ? "fit-content" : "39px")};
  background: ${({ legal, theme }) => (legal ? theme.colors.PRIMARY[25] : theme.colors.WHITE)};
  border: ${({ legal, theme }) =>
    legal ? `1px solid ${theme.colors.PRIMARY[400]}` : `1px solid ${theme.colors.GREYED[300]}`};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.GREY[500]};
  padding-left: 14px;
  padding: ${({ isArray }) => (isArray ? "10px 8px" : "")};
  display: flex;
  flex-direction: ${({ isArray }) => (isArray ? "column" : "row")};
  align-items: ${({ isArray }) => (isArray ? "flex-start" : "center")};
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const Value = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.GREYED[700]};
  width: 50%;
  margin-right: 10px;
  text-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FundManagersContainer = styled.div`
  width: 100%;
  a {
    text-decoration: none;
  }
  p {
    color: ${({ theme }) => theme.colors.DARK[500]};
  }
  text-wrap: wrap;
`;

export const HWMenuItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  color: #fff;
  height: 25px;
  padding: 8px 12px;
  background: ${({ isActive }) =>
    isActive ? "#a9bccc" : "#4A82A6"};
  margin: 0;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  margin-bottom: 0px;
`;

export const MenuItemText = styled.div`
  font-size: 19px;
  line-height: 24px;
  padding-left: 8px;
  font-weight: 500;
`;

export const AssetOnboardingSummaryHolder = styled.div`
  width: 95%;
  margin: auto;
`;

export const NavContainer = styled.div`
  max-width: 98%;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid #444444;
`;

export const NavItem = styled.div<{ isActive?: boolean}>`
  width: 25%;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.isActive ? "#295880" : "#444444"};
  border-bottom: ${(props) => props.isActive ? "5px solid #295880" : "none"};
  border-bottom-radius: 5px;
  cursor: pointer;
  text-wrap: wrap;
`;

export const BtnsHolder = styled.div`
  width: 100%;
  margin: 40px auto 40px;
  @media (max-width: 769px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
  }
`;

export const ToggleBtnHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin-right: 40px;
`;

export const DropDownHolder = styled.div`
  width: 100%;
  display: flex;
`;

export const ControlsHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 50px;
`;

export const HeaderTitle = styled.div`
  margin-top: 5px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  width: 180px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const Logo = styled.div<{ iconURL: string, isFundPartyManager?: boolean }>`
  width: 56px; 
  height: 44px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  margin: 0 14px 0 0;
  background-size: cover;
`;

export const Container = styled.div`
  padding: 10px;
  height: 100vh;
  overflow: hidden;
  background-color: #4A82A6;

  @media (max-width: 768px) {
    display: none;
  }
`;