import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import ClientTemplateRequestComponent from "components/funds/ClientTemplateRequestComponent";
import generalDetailsDummy from "data/generalDetailsCCID.json";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    FUND_MANAGERS_LIST_PATH,
    FUNDS_PATH
  } from "config/googleCloudConfig";
import { FundManagerType, AllFundManagerInfo } from "types/party";
import { getAccessToken } from "services/auth0/auth0";
import { useEffect, useState } from "react";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import LoadingSpinner from "components/LoadingSpinner";
import { FundType } from "types/funds";

const HubwiseClientTemplateRequest = () => {
  const { track } = useTrackActivity();
  const [fundManagersData, setFundManagersData]= useState<AllFundManagerInfo[]>([]);
  const [allFunds, setAllFunds] = useState<FundType[]>([]);
  const [fundsToAddArray, setFundsToAddArray] = useState<{[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}[]>([])
  const [loading, setLoading] = useState<boolean>(true);
  const token = getAccessToken();

  useEffectOnce(()=>{
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_ACTIVE_CCIDS);
  });

  useEffect(()=>{    
    if(token) {
      const indexFile = "index.json";
      const payload = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": indexFile,
        "filePath": `${FUND_MANAGERS_LIST_PATH}`
      }
  
      callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((fundManagers) => {
        fundManagers.map((fundManager: FundManagerType, index: number) => {
          const indexFileForFunds = "index.json";
          const payloadForFunds = {
            "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
            "fileName": indexFileForFunds,
            "filePath": `${FUNDS_PATH}/${fundManager.PID}`
          }

          callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForFunds, "test", "test")
          .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
          .then((data) => {
            const newArray = fundManagersData;
            newArray.push({fundManager: fundManager, funds: data.sort((a: { [x: string]: string; },b: { [x: string]: string; }) => a["Fund Full Name"].toLowerCase().localeCompare(b["Fund Full Name"].toLowerCase()))});
            setFundManagersData(newArray.sort((a,b) => (a.fundManager["Legal Name [PID]"]?.toLowerCase() ?? "").localeCompare(b.fundManager["Legal Name [PID]"]?.toLowerCase() ?? "")));
            if(fundManagersData.length === fundManagers.length) {
              setLoading(false);
            }
            const newObj: {[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}= {[fundManager.PID]:[] }
            data.map((fund: FundType) => {
              const newFundsArray = allFunds;
              newFundsArray.push(fund);
              setAllFunds(newFundsArray);
              newObj[fundManager.PID].push({"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL});
            })
            const newArr= fundsToAddArray;
            newArr.push(newObj);
            setFundsToAddArray(newArr);
          })
        })
      })
    }
   
  },[token])

  return (
    loading ? <LoadingSpinner /> :
    <ClientTemplateRequestComponent 
      dataForCcIdProfile= {generalDetailsDummy} 
      fundManagers= {fundManagersData} 
      allFunds= {allFunds}
      fundsToAddArray= {fundsToAddArray}
    /> 
  )       
}

export default HubwiseClientTemplateRequest;