import styled from "styled-components";

export const Container = styled.div`
  padding: 32px 32px 42px 32px;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  margin-bottom: 32px;
`;
export const Title = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 30px;
  color: #101828;
`;
export const HeaderInfo = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;
export const Content = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
export const ReportTracker = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
`;
export const LeftSection = styled.div`
  flex: 0.7;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
  }
`;
export const RightSection = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Analytics = styled.div`
  height: 350px;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  @media (max-width: 768px) {
    width: 420px;
    margin-right: 0;
  }
`;
export const AnalyticsHeader = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  padding: 20px;
`;
export const CardInfoContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 768px) {
    gap: 10px;
    justify-content: center;
  }
`;

export const ClosingRate = styled.div`
  height: 307px;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;
export const RateHeaderText = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #2a2a2a;
`;
export const RateSmallText = styled.div`
  padding-left: 20px;
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5b5b5b;
`;
export const TimeCardContainer = styled.div`
  width: 90%;
  margin-top: 24px;
`;
export const TopTimeCards = styled.div`
  display: flex;
  gap: 20px;
`;
export const BottomTimeCards = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 24px;
`;
export const Status = styled.div`
  margin-top: 25px;
  display: flex;
  width: 100%;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const StatusBox = styled.div`
  height: 162px;
  border: 1px solid #ffffff;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
`;
export const StatusWrapper = styled.div`
  flex: 1;
`;
export const StatusItem = styled.div`
  text-align: center;
`;
export const StatusItemTitle = styled.div`
  text-align: center;
  padding-bottom: 16px;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;
export const StatusImage = styled.img`
  padding-bottom: 12px;
  width: 95px;
  height: 87px;
`;
export const StatusInfo = styled.div<{ color: string }>`
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => {
    switch (color) {
      case "approved":
        return "#4CBB17";
      case "progress":
        return "#FF7800";
      case "denied":
        return "#D92D20";
      default:
        return "";
    }
  }};
`;
export const DocsSection = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
export const DocItem = styled.div``;
export const DocTitle = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  word-wrap: break-word;
`;
export const DocInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 21px;
`;
export const DocImage = styled.img``;
export const DocNumber = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;
export const DownloadBtn = styled.button`
  width: 81px;
  height: 29px;
  background: #2e1a61;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: white;
  gap: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  float: right;
  margin-top: 22px;
`;
export const DownloadIcon = styled.img``;
