import { FunctionComponent } from "react";
import { 
    Request,
    RequestHeader,
    RequestDetails 
} from "styles/funds/ActiveRequests.styled";
import { Link } from "react-router-dom";
import { PATH } from "constants/index";

const ActiveRequest: FunctionComponent<{
    request: {"PID": string, funds: number}
}> =  ({
    request
}) => {
      
    return (
        <Request>
            <Link to={PATH.FUND_DATA_REQUEST} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }}>
                <RequestHeader>
                    Channel: {request.PID}
                </RequestHeader>
                <RequestDetails>Funds to Confirm: {request.funds}</RequestDetails>
            </Link>
        </Request>
    )
};

export default ActiveRequest;