import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 32px;
`;

export const Title= styled.div`
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  font-weight: 700;
`;

export const RequestsContainer = styled.div`
    width: 98%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
    gap: 50px;
`;

export const Request = styled.div`
    width: 100%;
    padding: 12px;
    font-size: 18px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
`;

export const RequestHeader= styled.div``;
export const RequestDetails= styled.div`
    margin-top: 15px;
`;