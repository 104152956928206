import { FunctionComponent, useEffect, useState } from "react";
import { 
    Container,
    RequestsContainer,
    Title,
    Request 
} from "styles/funds/ActiveRequests.styled";
import ActiveRequest from "./ActiveRequest";

const ActiveRequestsComponent: FunctionComponent =  () => {

    const [activeRequests, setActiveRequests]= useState<{"PID": string; "funds": number}[]>([]);

    // TODO: get active requests from backend and adjust structure
    useEffect(()=>{
        setActiveRequests([
            {"PID": "GBSC286832", "funds": 3},
            {"PID": "DEZXLFJTOSPCH", "funds": 5},
            {"PID": "GBFEPSEP1XSRJ", "funds": 2}
        ])
    },[])
      
    return (
      <Container>
          <Title>Active Requests</Title>
          <RequestsContainer>
            {activeRequests.length > 0 && activeRequests.map((item, i) => (
                <ActiveRequest key= {i} request={item} />
            )) }
          </RequestsContainer>
      </Container>
    )
};

export default ActiveRequestsComponent;