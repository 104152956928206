import { FunctionComponent, useState } from "react";
import { 
    Container,
    SearchHolder,
    PartyProfileHolder,
    CloseIcon,
    closeIcon,
    ProfileContainer,
    ContactInfoHolder
} from "styles/funds/FundManagerT360.styled";
import PartyProfile from "components/party/profile";
import LoadingSpinner from "components/LoadingSpinner";
import BasicSearch from "components/searchComponents/BasicSearch";
import { Banner } from "styles/party/index.styled";
import { GeneralDetails } from "components/party/GeneralDetails";
import { GeneralDetailsType, PartyData } from "types/party";
import { Accordion } from "components/party/accordion/Accordion";
import { useAppData } from "hooks/useAppData";
import { ContactCover } from "styles/Alert.styled";
import { ContactInfo } from "components/party/ContactInfo";

const HubwiseFundManagerComponent: FunctionComponent =  () => {

    const [isSearch, setIsSearch] = useState<boolean>(true);
    const [partyDetails, setPartyDetails] = useState<PartyData | undefined>();
    const [id, setId] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { selectedParty: generalDetails } = useAppData();

    const handleClose = () => {
        setIsSearch(true);
        setId(undefined);
        setPartyDetails(undefined);
    }

    return (
        <Container>
            { isSearch ? 
                <SearchHolder>
                    <BasicSearch 
                        setIsSearch = { setIsSearch } 
                        setPartyDetails = { setPartyDetails } 
                        setId = { setId } 
                        setIsLoading = { setIsLoading }
                        isHubwise = { true }
                    />
                </SearchHolder> 
            : 
                <PartyProfileHolder>
                    <CloseIcon iconURL={closeIcon} onClick={handleClose} />
                    { (isLoading || partyDetails === undefined) ? <LoadingSpinner /> : 
                    
                        
                    partyDetails !== undefined &&
                    (
                        <ProfileContainer>
                            <Banner className="container">
                                <GeneralDetails data={generalDetails as GeneralDetailsType} />
                            </Banner>
                            <ContactInfoHolder>
                                <Accordion
                                    title="Contact Information"
                                    noTopBorder
                                    opened={true}
                                >
                                    <ContactCover>
                                        <ContactInfo
                                            data={partyDetails?.["Contact Information"]}
                                            showMap
                                            column
                                            hideTitle
                                        />
                                    </ContactCover>
                                </Accordion>
                            </ContactInfoHolder>
                            <PartyProfile partyId={id!} data={partyDetails} />
                        </ProfileContainer>
                    )
                    
                }
                </PartyProfileHolder>
            }
        </Container>
    )
};

export default HubwiseFundManagerComponent;