import { FunctionComponent } from "react";
import {
    CirclesChartContainer,
    CirclesTitle,
    CirclesChartHolder
} from "styles/funds/Onboarding.styled";
import ccIdList from "data/ccidList.json";
import CircularProgress from "../circularProgress/CircularProgress";
import { useState } from "react";

const CcIdProgress: FunctionComponent<{
    data:typeof ccIdList;
    showTitle: boolean;
    boxShadow?: boolean;
}> =  ({
    data,
    showTitle,
    boxShadow
}) => {
    return (
        <CirclesChartContainer boxShadow= {boxShadow}>
            {showTitle && <CirclesTitle>CCID Progress</CirclesTitle>}
            <CirclesChartHolder>
                    {data.map((ccid,index) => (
                            <CircularProgress
                            key={index}
                            width={100} 
                            height={100} 
                            title={ccid.name}
                            progressText={ccid.progress.percentage}
                            progressColor={ccid?.color?[0] ? ccid.color[0] : "#999999" : ""}
                        />                                                
                    ))}
            </CirclesChartHolder>
        </CirclesChartContainer>   
    )
};

export default CcIdProgress;