import styled from "styled-components";

export const Container = styled.div`
  white-space: nowrap;
  display: block;
  overflow-x: scroll;
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const Thead = styled.thead`
  margin-bottom: 13px;
`;
export const Tbody = styled.tbody<{ hide?: boolean }>`
  display: ${({ hide }) => hide && "none"};
`;
export const Th = styled.th`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  font-weight: 500;
  padding: 0 24px;
  padding-bottom: 8px;
`;
export const Td = styled.td<{ align?: string }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  line-height: 20px;
  padding: 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  text-wrap: wrap;
  word-break: break-word;
`;
export const SpecialTd = styled.td`
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto Condensed";
  height: 142px;
  text-align: left;
  vertical-align: top;
  padding: 0 20px;
  padding-top: 16px;
  max-width: 100% !important;
  width: 100% !important;
`;
export const InnerContent1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    cursor: pointer;
  }
`;
export const InnerContent2 = styled.div`
  padding-top: 12px;
  font-size: 14px;
  font-family: "Roboto Condensed";
  color: ${({ theme }) => theme.colors.GRAY[700]};
`;
export const InnerContent3 = styled.div`
  padding-top: 24px;
`;
export const RespondBtn = styled.button`
  float: right;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.PRIMARY[300]};
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  padding: 3px 7px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
export const InnerTd = styled.div`
  color: ${({ theme }) => theme.colors.DARK[500]};
  display: inline-block;
  margin-right: 5px;
`;
export const SpecialTr = styled.tr<{ infoOpen: boolean }>`
  display: ${(props) => (props.infoOpen ? "content" : "none")};
`;
export const Tr = styled.tr<{ body?: boolean }>`
  text-align: left;
  height: ${(props) => (props.body === true ? "72px" : "")};
  cursor: ${(props) => (props.body === true ? "pointer" : "")};
`;
export const Sn = styled.div`
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.colors.PRIMARY[400]};
  border-radius: 6px;
  font-size: 8px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  float: left;
  margin-right: 12px;
`;
export const Dot = styled.div`
  background-color: ${({ theme }) => theme.colors.SECONDARY[200]};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;
export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`;
export const PaginationButton = styled.button<{ disabled?: boolean}>`
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  width: 78px;
  background-color: ${(props) => (props.disabled === true ? "#EDEFEF" : "#fff" )};
  cursor: ${(props) => (props.disabled === true ? "" : "pointer" )};
  color: ${({ theme }) => theme.colors.GRAY[700]};
  line-height: 20px;
  font-weight: 700;
  font-family: "Roboto Condensed";
`;
export const PaginationPosition = styled.div``;
export const ModalContainer = styled.div``;
export const EmptySpan = styled.span`
  display: flex;
  align-items: center;
`;
export const SpanWrapper = styled.span``;
export const TableTime = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
`;
export const TableDate = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.GRAY[900]};
`;
export const Input = styled.input`
  margin-top: 5px;
  padding: 7px 0;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: 0;
  color: ${({ theme }) => theme.colors.DARK[500]};
  background-color: ${({ theme }) => theme.colors.WHITE};
`;

export const ExtraButton = styled.button`
  padding: 5px 20px;
  border-radius: 5px;
`;

export const NoResult = styled.div<{ show?: boolean }>`
  height: 100px;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

export const Extra = styled.div`
  padding: 10px 20px;
  margin-bottom: 20px;
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
`;

export const Status = styled.div<{ status: string }>`
  height: 22px;
  width: 56px;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case "ACTIVE":
        return theme.colors.SECONDARY[50];
      case "UNREAD":
        return theme.colors.TERTIARY[100];
      case "CLOSED":
        return theme.colors.PRIMARY[100];
      default:
        return theme.colors.PRIMARY[100];
    }
  }};
  color: ${({ status, theme }) => {
    switch (status) {
      case "ACTIVE":
        return theme.colors.SECONDARY[300];
      case "UNREAD":
        return theme.colors.TERTIARY[200];
      case "CLOSED":
        return theme.colors.PRIMARY[200];
      default:
        return theme.colors.PRIMARY[100];
    }
  }};
  font-size: 12px;
  border-radius: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
