import { FunctionComponent } from "react";
import { 
    OnboardingMainContainer,
} from "styles/funds/Onboarding.styled";
import ccIdList from "data/ccidList.json";
import OnboardingBox from "./OnboardingBox";
import NavBox from "./NavBox";

const HubwiseOnboardingComponent: FunctionComponent =  () => {
    return (
        <OnboardingMainContainer>
            <OnboardingBox data={ccIdList}/>
            <NavBox data={ccIdList}/>
        </OnboardingMainContainer>
    )
};

export default HubwiseOnboardingComponent;