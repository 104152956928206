import { FunctionComponent } from "react";
import { 
    Label,
    Value, 
    Container,
    LeftContainer,
    RightContainer,
    LabelValueHolder,
} from "styles/funds/AssetOnboardingSummaryCategory.styled";
import { 
    ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS,
    ASSET_ONBOARDING_ACTION_ITEMS 
} from "constants/hubwiseDashboard";
import CircularProgress from "../circularProgress/CircularProgress";

const AssetOnboardingSummaryCategory: FunctionComponent<{
    fundManagers?: number,
    funds?: number,
    isMyView?: boolean
}> = ({
    fundManagers,
    funds,
    isMyView
}) => {

    const percentages = [100, 0, 0, 0];

    return (
      <Container>
        {!isMyView && 
             <LeftContainer>
                <LabelValueHolder>
                    <Label>{ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS.TOTAL_NO_FUND_MANAGERS}: </Label>
                    <Value>{fundManagers}</Value>
                </LabelValueHolder>
                <LabelValueHolder>
                    <Label>{ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS.TOTAL_NO_FUNDS}: </Label>
                    <Value>{funds}</Value>
                </LabelValueHolder>
            </LeftContainer>
        }
        <RightContainer>
            {ASSET_ONBOARDING_ACTION_ITEMS.map((item: any, index:number) => (
                <CircularProgress
                    key={index}
                    width={100} 
                    height={100} 
                    title={item}
                    progressText={percentages[index]}
                />                                                
            ))}
        </RightContainer>
      </Container>
    );
  }
  
  export default AssetOnboardingSummaryCategory;
  