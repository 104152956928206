import type { FunctionComponent } from "react";
import { Container } from "styles/party/funds/index.styled";
import { FundDocumentsType } from "types/funds";
import GroupedDetails from "./GroupedDetails";
import { useEffect, useState } from "react";
import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  FUNDS_PATH,
  FUNDS_DOCUMENTS_PATH
} from "config/googleCloudConfig";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";

const Funds: FunctionComponent<{
  data?: any;
  fundId: string;
  partyId?: string;
  fundDocuments: FundDocumentsType;
  token?: string; 
  hideSubFund?: boolean;
}> = ({ fundId, data, token, partyId, fundDocuments, hideSubFund }) => {

  const [fundData, setFundData] = useState<[]>();
  const [fundsDocumentsList, setFundsDocumentsList]= useState<[]>();
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    if(!hideSubFund) {
      const fileName= `${fundId}.json`;
      const payload = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": fileName,
        "filePath": `${FUNDS_PATH}/${partyId}`
      }
      if(token) {
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
            setFundData(data);
            setLoading(false);
        })
      }      
      //TODO: funds documents are not yet properly retrieved, to be fixed: retrieve pdf files instead of json
      const fileNameForDocs = `${fundId}.json`;
      const payloadForFundsDocs = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": fileNameForDocs,
        "filePath": `${FUNDS_DOCUMENTS_PATH}/${fundId}`
      }

      if(token) {
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForFundsDocs, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
            console.log("fund docs: ", data);
        })
      }
    }
  },[fundId])

  return loading ? (
    <LoadingSpinnerDiv>
      <LoadingSpinner />
    </LoadingSpinnerDiv>
  ) : (
    <Container>
      <GroupedDetails 
        data={fundData} 
        fundDocuments={fundDocuments} 
        fundId= {fundId}
      />
    </Container>
  );
};

export default Funds;
