export const MY_VIEW_TABS = ["User Desktop View", "ALICE"] as MY_VIEW_TAB[];
export enum MY_VIEW_TAB {
  USER_VIEW = "User Desktop View",
  ALICE = "ALICE"
}

export const ONBOARDING_TABS = ["Fund Request", "Fund Link"] as ONBOARDING_TAB[];
export enum ONBOARDING_TAB {
    FUND_REQUEST = "Fund Request",
    FUND_LINK = "Fund Link"
}

export const ASSET_ONBOARDING_SUMMARY_TABS = ["All Funds - In Catalog", "All Funds - Not in Catalog", "Direct to FM", "Request FM"] as ASSET_ONBOARDING_SUMMARY_TAB[];
export enum ASSET_ONBOARDING_SUMMARY_TAB {
    ALL_FUNDS_IC = "All Funds - In Catalog",
    ALL_FUNDS_NIC = "All Funds - Not in Catalog",
    DIRECT_FM = "Direct to FM",
    REQUEST_FM = "Request FM"
}

export enum ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS {
  TOTAL_NO_FUND_MANAGERS= "Fund Managers",
  TOTAL_NO_FUNDS = "Funds"
}

export const ASSET_ONBOARDING_ACTION_ITEMS = [ "To be requested", "Requested", "Awaiting", "Confirmed" ]

export const WIDGET_SELECTION_MAIN_TABS= ["Onboarding", "Reports"] as WIDGET_SELECTION_MAIN_TAB[];
export enum WIDGET_SELECTION_MAIN_TAB {
  ONBOARDING= "Onboarding",
  REPORTS= "Reports"
}

export const ONBOARDING_WIDGETS_TAB_ITEMS= ["Asset Onboarding", "Onboarding Time", "CCID Progress"] as ONBOARDING_WIDGETS_TAB_ITEM[];

export enum ONBOARDING_WIDGETS_TAB_ITEM {
  ASSET_ONBOARDING= "Asset Onboarding",
  ONBOARDING_TIME= "Onboarding Time",
  CCID_PROGRESS= "CCID Progress"
}

export const REPORTS_WIDGETS_TAB_ITEMS= ["Financial", "Transactions"] as REPORTS_WIDGETS_TAB_ITEM[];

export enum REPORTS_WIDGETS_TAB_ITEM {
  FINANCIAL = "Financial",
  TRANSACTIONS= "Transactions"
}
