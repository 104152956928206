import { FunctionComponent } from "react";
import { Container } from "../../styles/hubwise/HubwiseClientManager.styled";

const HubwiseClientManagerComponent: FunctionComponent =  () => {
    
    return (
        <Container>
            In this section you will see Client Information (Client Profile) together with all legal documentation and regulatory due diligence such as KYC and AML related content.
        </Container>
    )
};

export default HubwiseClientManagerComponent;