import { FunctionComponent, useEffect, useState } from "react";
import { 
  AssetOnboardingSummaryHolder,
  DropDownHolder
} from "styles/funds/ClientTemplateRequest.styled";
import AssetOnboardingSummaryCategory from "components/hubwise-dashboard/clientTemplateRequest/AssetOnboardingSummaryCategory";
import { FilterByCategory } from "components/filter/Index";
import useFilters from "hooks/useFilter";
import { AllFundManagerInfo } from "types/party";
import { FundType } from "types/funds";
import { Dispatch, SetStateAction } from "react";

const AssetOnboardingNavHolderComponent: FunctionComponent<{
    allFunds?: FundType[];
    setFilteredFunds?: Dispatch<SetStateAction<FundType[]>>;
    allFMs?: AllFundManagerInfo[];
    filteredFMs?:  AllFundManagerInfo[];
    isMyView?: boolean
}> = ({
    allFunds,
    setFilteredFunds,
    allFMs,
    filteredFMs,
    isMyView
}) => {
    const { filteredData, setFilter } = useFilters(allFunds ? allFunds : []);
    useEffect(()=>{
        if(setFilteredFunds) {
            setFilteredFunds(filteredData);
        }
    },[filteredData])

    return (
        <AssetOnboardingSummaryHolder>
            <h1>Asset Onboarding Request</h1>
            {!isMyView && 
                <DropDownHolder>
                    <FilterByCategory setFilter={setFilter} />
                </DropDownHolder>
            }            
            <AssetOnboardingSummaryCategory 
                fundManagers={filteredFMs && filteredFMs.length > 0 ? filteredFMs.length : allFMs?.length} 
                funds={filteredData.length}
                isMyView = {isMyView}
            />  
            
        </AssetOnboardingSummaryHolder>
    );
  }
  
  export default AssetOnboardingNavHolderComponent;
  