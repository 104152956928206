import styled from "styled-components";

export const FundsManagerContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
    padding: 12px;
    border-top: 1px solid;
    align-items: center;
`;

export const FundManagerName = styled.div`
    font-weight: 700;
    font-size: 24px;
`;

export const FundManagerItemLeftArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    @media (max-width: 768px) {
        flex: auto;
        padding: 0 0 25px 0;
    }
`;

export const FundManagerItemRightArea = styled.div`
  width: 100%;
`;

export const RightIcons = styled.div`
    margin-right: 0;
`;

export const AccordionActionButton = styled.div`
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
`;

export const CloseAccordionIcon = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
  background-image: url(${`${process.env.PUBLIC_URL}/assets/closeAccordionIcon.svg`});
  background-repeat: no-repeat;
  margin-left: 12px;
  &:hover {
    opacity: 0.5;
  }
`;
export const OpenAccordionIcon = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
  background-image: url(${`${process.env.PUBLIC_URL}/assets/openAccordionIcon.svg`});
  background-repeat: no-repeat;
  margin-left: 12px;
  &:hover {
    opacity: 0.5;
  }
`;

export const FundName= styled.div`
  font-size: 21px;
  font-weight: 600;
  white-space: break-spaces;
  padding-left: 20px;
  max-width: 78vw;
`;

export const AccordionTitleHolder= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FundDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 10px 0;

  p {
    margin: 1px 0;
    white-space: break-spaces;
    font-family: Roboto Condensed;
    font-weight: 550;
    font-size: 20px;
    &:first-child {
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
    }

    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.GREY[500]};
      font-weight: 500;
      font-family: "Roboto Condensed";
      font-size: 18px;
      line-height: 18.75px;
      text-align: left;
      word-wrap: break-word;
      max-width: 40%;
    }
  }
`;

export const IdsHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
  padding-left: 40px;
  text-wrap: wrap;
  margin-top: 25px;
`;

export const IdsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: break-spaces;
  overflow: hidden;
`;

export const FundRequestFormContainer = styled.div`
  margin-top: 20px;
  padding-left: 20px;
`;

export const FundDivHolder = styled.div``;