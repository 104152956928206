import styled from "styled-components";

export const MyViewContainer = styled.div`
  height: 100%;
  padding: 32px;
`;

export const Title= styled.div`
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  font-weight: 700;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;