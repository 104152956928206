import styled from "styled-components";

export const Container = styled.div``;
export const CContainer = styled.div`
  padding: 12px;
  .title-cover {
    text-align: center;
  }
  & p {
    text-align: left;
  }
`;

export const EachCover = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Each = styled.div`
  width: 90%;
  margin-right: 20px;
  min-width: 300px;

  @media (min-width: 768px) {
    width: 45%;
  }
`;

export const Head = styled.div<{ small?: boolean }>`
  display: flex;
  align-items: center;
  p {
    margin: 1px 0;
    white-space: break-spaces;
    font-family: Roboto Condensed;
    &:first-child {
      font-weight: 600;
      padding-left: 0;
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
      width: ${({ small }) => (small ? "130px" : "300px")};
    }

    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
      font-weight: 600;
      font-family: Roboto Condensed;
      font-size: 16px;
      text-align: end;
    }
  }
`;

export const IdentifierList = styled.div<{ small?: boolean }>`
  display: flex;
  align-items: center;

  p {
    margin: 1px 0;
    white-space: break-spaces;
    font-family: Roboto Condensed;
    &:first-child {
      width: ${({ small }) => (small ? "130px" : "300px")};
      padding: 10px;
      padding-left: 0;
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
    }

    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.GREY[500]};
      font-weight: 500;
      font-family: "Roboto Condensed";
      font-size: 16px;
      line-height: 18.75px;
      text-align: justify;
    }
  }
`;

export const IdentifierCover = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 20px;
    font-family: "Roboto Condensed";
  }
`;

export const Table = styled.table<{ large?: boolean }>`
  min-width: 33%;
  width: 100%;

  thead {
    background-color: ${({ theme }) => theme.colors.PRIMARY[15]};
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    font-family: "Roboto Condensed";
  }
`;

export const TH = styled.th<{ position?: "center" | "right" }>`
  text-align: ${({ position }) =>
    position === "center" ? "center" : position === "right" ? "right" : "left"};
  padding: 10px 20px;
  font-weight: 500;
  font-family: "Roboto Condensed";
`;
export const TD = styled.td<{ position?: "center" | "right" }>`
  text-align: ${({ position }) =>
    position === "center" ? "center" : position === "right" ? "right" : "left"};
  padding: 10px 20px;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.GREY[500]};
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto Condensed";
  line-height: 18.75px;
`;

export const List = styled.div<{ small?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));
  padding: 10px 0;
  align-items: center;

  &div {
    align-items: center;
  }
`;

export const ListPLabel = styled.p`
  margin: 1px 0;
  white-space: break-spaces;
  font-family: Roboto Condensed;
  font-weight: 550;
  font-size: 20px;
  &:first-child {
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
  }
`;

export const ListPValue = styled.p`
  color: ${({ theme }) => theme.colors.GREY[500]};
  font-weight: 500;
  font-family: "Roboto Condensed";
  font-size: 18px;
  line-height: 18.75px;
  word-wrap: break-word;
  max-width: 400px;
  text-align: left;
`;

export const ListHolder = styled.div<{ twoColumns?: boolean }>`
  display: grid;
  grid-template-columns: ${({ twoColumns }) => (twoColumns ? "repeat(2, 1fr)" : "repeat(1, 1fr)")};
  @media(max-width:769px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Flex = styled.div<{ mb?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: ${({ mb }) => (mb ? "40px" : "")};
  @media (max-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Text = styled.div<{ light?: boolean }>`
  font-weight: ${({ light }) => (light ? 500 : 700)};
  font-size: 26px;
  font-family: Roboto Condensed;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  text-align: left;
  margin-bottom: 20px;
  word-wrap: break-word;
  text-wrap: wrap;
  border-bottom: 1px solid #808080;
`;

export const TableCover = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 12px;
  .title-cover {
    text-align: center;
  }
`;

export const Cover = styled.div`
  display: flex;

  .each {
    margin-right: 40px;
  }
`;

export const ListCover = styled.div`
  width: 100%;
  @media (max-width: 769px) {
    width: 95%;
  }
  font-family: "Roboto Condensed";
`;

export const InfoList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 34px;
  height: max-content;
  @media (max-width: 768px) {
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TopHoldingNoDataContainer = styled.div`
  margin-top: -10px;
`;

export const FundsListContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 12px;
  margin-top: 20px;
  width: 100%;
  .title-cover {
    text-align: center;
  }
  & p {
    text-align: left;
  }
`;
