import styled from "styled-components";

export const LinearProgressHolder = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 20px;
`;

export const Label = styled.div`
    width: 20%;
    font-size: 16px;
    line-height: 24px;
    padding-left: 8px;
    font-weight: bold;
    color: #444444;
    text-wrap: wrap;
`;

export const ProgressBarContainer = styled.div<{ color: string, height: number }>`
    width: 70%;
    @media (max-width: 769px) {
        width: 60%;
    }
    height: ${(props) => `${props.height}px`};
    background-color: ${(props) => props.color};
    overflow: hidden;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    margin-right: 5px;
`;

export const ProgressBar = styled.div<{ color: string, height: number, value: number }>`
    width: ${(props) => `${props.value}%`};
    height: ${(props) => `${props.height}px`};
    background-color: ${(props) => props.color}
`;

export const DaysHolder = styled.div`
    width: 10%;
    word-wrap: break-word;
    text-wrap: wrap;
`;