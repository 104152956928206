import { FunctionComponent, useState, useEffect } from "react";
import { 
    UserDesktopViewContainer,
    WidgetsContainer 
} from "styles/funds/UserDesktopView.styled";
import AddWidgetComponent from "./widgets/AddWidget";
import { getItem } from "utils/localStorage";
 import AverageOnboardingTime from "./onboarding/AverageOnboardingTime";
 import ccIdList from "data/ccidList.json";
 import CcIdProgress from "./onboarding/CcIdProgress";
 import WidgetComponent from "./widgets/WidgetComponent";
 import AssetOnboardingSummaryCategory from "./clientTemplateRequest/AssetOnboardingSummaryCategory";
import LoadingSpinner from "components/LoadingSpinner";

const UserDesktopViewComponent: FunctionComponent =  () => {
    const [userPreferences, setUserPreferences] = useState<string[]>([]);
    const [isOption,setIsOption] = useState<boolean>(false);
    const [activeId, setActiveId]= useState<number>();
    const [updated, setUpdated]= useState<boolean>(false);
    const [isLoading, setIsLoading]= useState<boolean>(false);

    useEffect(()=>{
        if(updated) {
            setIsLoading(true);
            setUserPreferences(getItem("userPreferences", []));
            setUpdated(false);
            setIsLoading(false);
        }
    },[updated])

    useEffect(()=>{
        setUserPreferences(getItem("userPreferences", []));
    },[])
    
    return isLoading ? <LoadingSpinner /> : (
        <UserDesktopViewContainer>
            <AddWidgetComponent userPreferences={userPreferences} setUpdated= {setUpdated}/>
            <WidgetsContainer>
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Average Onboarding Completion Time" 
                    element={<AverageOnboardingTime data={ccIdList} showTitle={false}/>}
                    display={userPreferences.find(acc => acc === "Onboarding Time") ? "true" : "false"}
                    id={1}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Onboarding Time"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="CCID Progress" 
                    element={<CcIdProgress data={ccIdList} showTitle={false}/>}
                    display={userPreferences.find(acc => acc === "CCID Progress") ? "true" : "false"}
                    id={2}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "CCID Progress"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Asset Onboarding Request" 
                    element={<AssetOnboardingSummaryCategory 
                        fundManagers={0} 
                        funds={0}
                        isMyView = {true}
                    /> }
                    display={userPreferences.find(acc => acc === "Asset Onboarding") ? "true" : "false"}
                    id={3}
                    activeId= {activeId}
                    setActiveId= {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Asset Onboarding"
                    setUpdated= {setUpdated}
                />
            </WidgetsContainer>
        </UserDesktopViewContainer>
    )
};

export default UserDesktopViewComponent