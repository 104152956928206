import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  @media (max-width: 768px) {
    grid-template-columns: 100% 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15% 85% 1fr;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-height: 99vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Nav = styled.div`
  display: flex;
  padding: 10px;
  margin-bottom: 45px;
  background: linear-gradient(178deg, #4A82A6 60%, #5ECCD9 116.67%);

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const OutletCover = styled.div`
  overflow-y: none;
`;
