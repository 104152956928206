import styled from "styled-components";
import { SEARCH_CLASS_TYPE } from "constants/filter";

export const Container = styled.div`
    max-width: 100%;
    margin-bottom: 20px;
    padding: 12px;
`;

export const Title = styled.div`
    font-size: 30px;
    font-weight: 700;
    width: 100%;
    margin-top: 40px;
    text-align: center;
`;

export const QHolder = styled.form`
    width: 100%;
    margin-top: 40px;
`;

export const Section = styled.div`
  padding: 24px 0;
  margin-bottom: 12px;
  border-top: 2px solid #36407380;
`;

export const QAccordionHolder = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
  flex-direction: column;
  margin-top: 40px;
`;

export const QPermissionHolder = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  margin-top: 40px;
`;

export const QRow = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EAECF0;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 769px) {
      flex-direction: column;
      align-items: start;
    }
`;

export const InputWrapper = styled.div<{ classType?: SEARCH_CLASS_TYPE }>`
  display: flex;
  align-items: center;
  background-color: ${({ classType }) =>
    classType === SEARCH_CLASS_TYPE.PRIMARY && "rgba(54, 64, 115, 0.1)"};
  border: ${({ classType }) => classType === SEARCH_CLASS_TYPE.DEFAULT && "1px solid #D0D5DD"};
  padding: 13px 10px;
  border-radius: 6px;
  flex-basis: 50%;
  column-gap: 10px;
  width: 50%;
  @media (max-width: 769px) {
    width: 95%;
  }
`;

export const Input = styled.input<{ classType?: SEARCH_CLASS_TYPE, border?: boolean}>`
  font-weight: 400;
  font-size: ${({ classType }) => (classType === SEARCH_CLASS_TYPE.DEFAULT ? "16px" : "12px")};
  outline: none;
  border: ${({ border }) => (border ? "1px solid red" : "none")};
  height: 56px;
  color: #000000;
  background: #F8F8F8;
  width: 100%;
  font-size: 16px;
  flex: 1;
  padding: 3px 40px 0 15px;
  &:focus {
    outline: 1px solid #4A82A6;
  }
`;

export const FormLabel = styled.label`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  width: 50%;
  padding: 10px;
  text-wrap: wrap;
  @media (max-width: 769px) {
    width: 95%;
  }
`;

export const SubmitBtnHolder = styled.div`
  width: 20%;
  margin: 30px auto 30px;
`;

export const ProgressContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-items: flex-start;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 90%;
`;

export const FundsLeftToConfirmP= styled.p`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-right: 40px;
  width: 50%;

`;

export const ConfirmationP= styled.p`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-left: 40px;
`;

export const HeaderSectionHolder= styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 20px;
  margin-top: 40px;
  width: 100%;
`;

export const IdsHolder= styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
  width: 90%;
  padding: 20px;
  @media (max-width: 769px) {
    margin-top: 15px;
  }
`;

export const NavHolder= styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
  width: 90%;
  padding: 20px;
`;

export const IdsDiv= styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
`;

export const LabelP= styled.p`
  color: #364073;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const ValueP= styled.p`
  margin-left: 30px;
  font-size: 18px;
  line-height: 21px;
  color: #808080;
`;

export const HeaderSectionTitle= styled.div`
  text-align: center;
  color: #364073;
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;
  margin-top: 20px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-left: 40px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4A82A6;
    transition: .4s;
    border-radius: 24px;
  }

  & span:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #ccc;
  }

  input:checked + span:before {
    transform: translateX(16px);
  }
`;