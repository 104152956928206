import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 32px;
`;

export const Title= styled.div`
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  font-weight: 700;
`;

export const FundsContainer= styled.div`
  margin-top: 50px;
`;