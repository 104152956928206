import { FunctionComponent } from "react";
import {
    BottomContentContainer,
    FiltersContainer,
    TableContainer
} from "styles/funds/Onboarding.styled";
import masterBuylist from "data/masterBuylistDummy.json";
import useFilters from "hooks/useFilter";
import { Search } from "../filter/Search";
import { 
    Table,
    Thead,
    Tbody,
    Td,
} from "styles/admin/AdminTable.styled";
import { MasterBuylistContainer, Th, Tr } from "styles/funds/MasterBuylist.styled";

const HubwiseMasterBuylistComponent: FunctionComponent<{data: typeof masterBuylist}> =  ({data}) => {

    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str });
    };
    
    return (
        <MasterBuylistContainer>
            <BottomContentContainer>
                <FiltersContainer>
                    <Search setData={setData} />
                </FiltersContainer>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Customer Code</Th>
                                <Th>ISIN</Th>
                                <Th>Instrument Name</Th>
                                <Th>Broker Code</Th>
                                <Th>UII Instrument</Th>
                                <Th>OCF</Th>
                                <Th>Transaction Cost</Th>
                                <Th>AMC Charge %</Th>
                                <Th>Total Expense %</Th>
                                <Th>Additional Expense %</Th>
                                <Th>Exchange</Th>
                                <Th>TEX Member</Th>
                                <Th>ISIN Status</Th>
                                <Th>Market Currency</Th>
                                <Th>Trade</Th>
                                <Th>Instrument Type</Th>
                                <Th>Instrument Subtype</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredData.map((instrument,index) => (
                                <Tr body key={index} data-testid="row">
                                    <Td>{instrument.customerCode}</Td>
                                    <Td>{instrument.ISIN}</Td>
                                    <Td>{instrument.instrumentName}</Td>
                                    <Td>{instrument.brokerCode}</Td>
                                    <Td>{instrument.uiiInstrument}</Td>
                                    <Td>{instrument.ocf}</Td>
                                    <Td>{instrument.transactionCost}</Td>
                                    <Td>{instrument.amcCharge} %</Td>
                                    <Td>{instrument.totalExpense} %</Td>
                                    <Td>{instrument.additionalExpense} %</Td>
                                    <Td>{instrument.exchange}</Td>
                                    <Td>{instrument.texMember}</Td>
                                    <Td>{instrument.ISINStatus}</Td>
                                    <Td>{instrument.marketCurrency}</Td>
                                    <Td>{instrument.trade}</Td>
                                    <Td>{instrument.instrumentType}</Td>
                                    <Td>{instrument.instrumentSubtype}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </BottomContentContainer>
        </MasterBuylistContainer>
    )
};

export default HubwiseMasterBuylistComponent;