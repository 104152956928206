import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import NoData from "components/noData/NoData";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { PartyDetails } from "types";
import { ContactInformation } from "types/party";
import { TRACKER } from "constants/tracker";
import { Accordion } from "../accordion/Accordion";
import Funds from "../../party/funds/index";
import { 
  FundDocumentsType,
  AgentCodesItem,
  AgentCodesContactInfo 
} from "types/funds";
import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  FUNDS_PATH,
  AGENT_CODES_FILE_PATH
} from "config/googleCloudConfig";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import PartyProfile from "components/party/profile";
import { GeneralDetailsType, PartyData } from "types/party";
import { useAppData } from "hooks/useAppData";
import { GET_PARTY_DETAILS } from "config/googleCloudFunctionsConfig";
import { useToast } from "hooks/useToast";
import { ContactInfo } from "components/party/ContactInfo";
import { ContactCover } from "styles/party/profile/index.styled";
import FundManagerCard from "./fundManager/FundManagerCard";
import { FundManagerCardsHolder } from "styles/party/keyPeople/TilePerson.styled";
import NoDataContent from "components/noData/NoDataContent";

export function ManagedFunds({
  token,
  data,
  partyId,
}: {
  token?: string;
  data: PartyDetails;
  partyId: string;
}) {
  
  const { id } = useParams<{ id: string }>();
  const [fundsData, setFundsData] = useState<[]>([]);
  const [loading, setLoading] = useState(false);

  const { track } = useTrackActivity();
  const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | null>(null);
  const [profileDetails, setProfileDetails] = useState<PartyData>();
  const [agentCodesDetails, setAgentCodesDetails] = useState<AgentCodesItem>();
  const [contactInfo, setContactInfo]= useState<AgentCodesContactInfo[]>();
  const { user, selectedParty: generalDetails, setSelectedParty } = useAppData();
  const [_, setUserName] = useState<string | undefined>("");
  const { toast } = useToast();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PARTY_PROFILE, TRACKER.FUNDS, {
      party_name: (data["Contact Information"] as ContactInformation).Name,
      party_id: partyId,
    });
  });

  useEffect(()=>{
    setLoading(true);

    const indexFile = "index.json";
    const payload = {
      "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "fileName": indexFile,
      "filePath": `${FUNDS_PATH}/${partyId}`
    }

    callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
    .then((data) => {
      setFundsData(data);
    })

    const agentCodesFileName= "sierra_pid_agent_fund_managers-15-05-24.json";

    const agentCodesPayload = {
      "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "fileName": agentCodesFileName,
      "filePath": AGENT_CODES_FILE_PATH
    }

    callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, agentCodesPayload, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
    .then((data: AgentCodesItem[]) => {
      data.map((agent: AgentCodesItem) => {
        if(agent.PID === id) {
          if (agent["Contact Info"].length > 1 ) {
            agent["Contact Info"].sort((a,b) => {
              if (a["Person Flag"] === b["Person Flag"]) {
                return 0;
              } else if (a["Person Flag"] === "Y") {
                return -1;
              } else {
                return 1;
              }
            })
          }
          setAgentCodesDetails(agent);
        }
      })
    })

    const params = {
      pid: id
    }

    callGCFunctionWIthAccessToken(token, GET_PARTY_DETAILS, params, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
          if (data) {
            // get general details from data and set
            setSelectedParty({ ...(data?.["General Details"] as GeneralDetailsType), id });

            // set the rest of the data as profileDetails
            const details = { ...data };

            delete details?.["General Details"];

            setProfileDetails(details);
            setUserName(user!.name);
            setLoading(false);
          }
        })
        .catch((err: Error) => toast(err.toString()));
  },[partyId, token])

  return loading ? (
    <LoadingSpinnerDiv>
      <LoadingSpinner />
    </LoadingSpinnerDiv>
  ) : (
    <>
      <Accordion
        title="Contact Information"
        noTopBorder
        opened
      >
        <ContactCover>
          <FundManagerCardsHolder>
            {agentCodesDetails?.["Contact Info"] && agentCodesDetails?.["Contact Info"].length > 0  ? 
              agentCodesDetails["Contact Info"].map((item, index) => (
                <FundManagerCard key={index} data={item} />
              ))
              : <NoDataContent />
            }
          </FundManagerCardsHolder>
          <ContactInfo
              data={profileDetails?.["Contact Information"]}
              showMap
              column
              hideTitle
          />
        </ContactCover>
      </Accordion>
      <Accordion title="Funds" opened noTopBorder borderBottom>
        <>
        {fundsData.length > 0  ? fundsData.map((fund, index) => (
            <Accordion key={index} title={`Fund Name - ${fund["Fund Full Name"]}; FIGI - ${fund["FIGI"]}; ISIN - ${fund["ISIN"]}; SEDOL - ${fund["SEDOL"]}`} 
              children={
                <Funds 
                  fundId={fund["Record Id"]} 
                  partyId={partyId} token={token} 
                  fundDocuments={fundDocuments!}
                />
              } 
            small
          />
          )) : <NoData />}
        </>
      </Accordion>
      {profileDetails && 
        <Accordion title="Profile" opened={false} noTopBorder>
          <PartyProfile partyId={id!} data={profileDetails} />
        </Accordion>
      }
    </>
  );
}
